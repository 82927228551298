export type Rect = { x: number; y: number; width: number; height: number };
export type Point = { x: number; y: number };

export function rectangleFromPoints(points: readonly Point[]): Rect | undefined {
  const n = points.length;
  if (n < 1) {
    throw new Error('at least two points are necessary');
  }

  const min: Point = { x: Number.MAX_VALUE, y: Number.MAX_VALUE };
  const max: Point = { x: Number.MIN_VALUE, y: Number.MIN_VALUE };
  for (const p of points) {
    min.x = Math.min(p.x, min.x);
    min.y = Math.min(p.y, min.y);
    max.x = Math.max(p.x, max.x);
    max.y = Math.max(p.y, max.y);
  }

  return { x: min.x, y: min.y, width: max.x - min.x, height: max.y - min.y };
}

export function rectangleIntersects(r1: Rect, r2: Rect): boolean {
  return !(r2.x > r1.x + r1.width || r2.x + r2.width < r1.x || r2.y > r1.y + r1.height || r2.y + r2.height < r1.y);
}

export function rectangleIntersection(a: Rect, b: Rect): Rect | undefined {
  const x = Math.max(a.x, b.x);
  const num1 = Math.min(a.x + a.width, b.x + b.width);
  const y = Math.max(a.y, b.y);
  const num2 = Math.min(a.y + a.height, b.y + b.height);
  if (num1 >= x && num2 >= y) return new Rectangle(x, y, num1 - x, num2 - y);
  else return undefined;
}

export function rectangleIntersectionArea(a: Rect, b: Rect): number {
  const intersection = rectangleIntersection(a, b);
  return intersection !== undefined ? intersection.width * intersection.height : 0;
}

export function rectangleArea(rect: Rect): number {
  return rect.width * rect.height;
}

export class Rectangle implements Rect {
  x: number;
  y: number;
  width: number;
  height: number;
  type: any;
  constructor(x = 0, y = 0, width = 0, height = 0) {
    this.x = Number(x);
    this.y = Number(y);
    this.width = Number(width);
    this.height = Number(height);
  }
  get left() {
    return this.x;
  }
  get right() {
    return this.x + this.width;
  }
  get top() {
    return this.y;
  }
  get bottom() {
    return this.y + this.height;
  }
  get area() {
    return this.width * this.height;
  }
  static get EMPTY() {
    return new Rectangle(0, 0, 0, 0);
  }
  clone() {
    return new Rectangle(this.x, this.y, this.width, this.height);
  }
  copyFrom(rectangle: Rectangle): Rectangle {
    this.x = rectangle.x;
    this.y = rectangle.y;
    this.width = rectangle.width;
    this.height = rectangle.height;
    return this;
  }
  copyTo(rectangle: Rectangle): Rectangle {
    rectangle.x = this.x;
    rectangle.y = this.y;
    rectangle.width = this.width;
    rectangle.height = this.height;
    return rectangle;
  }
  contains(x: number, y: number) {
    if (this.width <= 0 || this.height <= 0) {
      return false;
    }
    if (x >= this.x && x < this.x + this.width) {
      if (y >= this.y && y < this.y + this.height) {
        return true;
      }
    }
    return false;
  }

  pad(paddingX = 0, paddingY = paddingX) {
    this.x -= paddingX;
    this.y -= paddingY;
    this.width += paddingX * 2;
    this.height += paddingY * 2;
    return this;
  }

  fit(rectangle: Rectangle) {
    const x1 = Math.max(this.x, rectangle.x);
    const x2 = Math.min(this.x + this.width, rectangle.x + rectangle.width);
    const y1 = Math.max(this.y, rectangle.y);
    const y2 = Math.min(this.y + this.height, rectangle.y + rectangle.height);
    this.x = x1;
    this.width = Math.max(x2 - x1, 0);
    this.y = y1;
    this.height = Math.max(y2 - y1, 0);
    return this;
  }

  ceil(resolution = 1, eps = 1e-3) {
    const x2 = Math.ceil((this.x + this.width - eps) * resolution) / resolution;
    const y2 = Math.ceil((this.y + this.height - eps) * resolution) / resolution;
    this.x = Math.floor((this.x + eps) * resolution) / resolution;
    this.y = Math.floor((this.y + eps) * resolution) / resolution;
    this.width = x2 - this.x;
    this.height = y2 - this.y;
    return this;
  }

  enlarge(rectangle: Rectangle) {
    const x1 = Math.min(this.x, rectangle.x);
    const x2 = Math.max(this.x + this.width, rectangle.x + rectangle.width);
    const y1 = Math.min(this.y, rectangle.y);
    const y2 = Math.max(this.y + this.height, rectangle.y + rectangle.height);
    this.x = x1;
    this.width = x2 - x1;
    this.y = y1;
    this.height = y2 - y1;
    return this;
  }

  intersects(r2: Rect) {
    return rectangleIntersects(this, r2);
  }

  intersection(b: Rect): Rect | undefined {
    return rectangleIntersection(this, b);
  }

  intersectionArea(b: Rectangle): number {
    return rectangleIntersectionArea(this, b);
  }

  toString() {
    return `[Rectangle x=${this.x} y=${this.y} width=${this.width} height=${this.height}]`;
  }
}

export function* generateSubRects(
  rect: Rect,
  count: number,
  mode: 'start' | 'center' | 'end' = 'start',
  axis: 'x' | 'y' = 'x',
  preferredAspectRatio: number | undefined = undefined,
  spacing: number = 0
): Generator<Rectangle> {
  let subWidth = axis == 'y' ? rect.width : (rect.width - spacing * (count - 1)) / count;
  let subHeight = axis == 'y' ? (rect.height - spacing * (count - 1)) / count : rect.height;
  const subAspectRatio = subWidth / subHeight;
  preferredAspectRatio = preferredAspectRatio || subAspectRatio;
  if (subAspectRatio > preferredAspectRatio) {
    subWidth = subHeight * preferredAspectRatio;
  } else {
    subHeight = subWidth / preferredAspectRatio;
  }

  let origin = { x: rect.x, y: rect.y };
  switch (mode) {
    case 'start':
      origin = { x: rect.x, y: rect.y };
      break;
    case 'center':
      origin =
        axis == 'x'
          ? {
              x: rect.x + rect.width / 2 - subWidth / 2 - (subWidth * (count - 1)) / 2,
              y: rect.y + rect.height / 2 - subHeight / 2,
            }
          : {
              x: rect.x + rect.width / 2 - subWidth / 2,
              y: rect.y + rect.height / 2 - subHeight / 2 - (subHeight * (count - 1)) / 2,
            };
      break;
    case 'end':
      origin =
        axis == 'x'
          ? { x: rect.x + rect.width - subWidth * count, y: rect.y }
          : { x: rect.x, y: rect.y + rect.height - subHeight * count };
      break;
  }
  const subRect = new Rectangle(origin.x, origin.x, subWidth, subHeight);

  for (let i = 0; i < count; i++) {
    subRect.x = origin.x + (axis == 'x' ? i * subWidth : 0);
    subRect.y = origin.y + (axis == 'y' ? i * subHeight : 0);
    yield new Rectangle(subRect.x, subRect.y, subWidth, subHeight);
  }
}
